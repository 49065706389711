export enum PUBLIC_ROUTES {
  LOGIN = '/login/:accountId/:eventGroupId',
  LANDING = '/site/:accountId/:eventGroupId',

  FORGOT_PASSWORD = '/forgot-password/:eventGroupId/:accountId',
  VERIFY_OTP = '/verify-otp/:eventGroupId/:accountId',
  RESET_PASSWORD = '/reset-password/:eventGroupId/:accountId',
  BLOCK_REGISTRANT = '/block-registrant/:accountId',

  NOT_FOUND = '/404',
}

export enum PROTECTED_ROUTES {
  BASE_ROUTE = '/:accountId/:eventGroupId',
}

export enum API_ROUTES {
  GET_AUTH_TOKEN = '/event-registrant/auth-token',
  REGISTRANT = '/registrant/microsite',

  GROUP_EVENT = 'event/microsite/event-by-group',
  GET_EVENT = 'event/get-event',
  EVENT_GROUPS = '/event/group/',

  EVENT_SITE_DETAILS = '/eventsite/details',
  REGISTER_EVENT_REGISTRANT = '/eventsite/register-event',
  REGISTER_VENUE_REGISTRANT = '/registrant/microsite/register-on-venue',
  SEARCH_VENUES = '/event-venue/search-by-zipcode',

  GET_REGISTRANT_FORMS = '/registrant-form',
  GET_EVENT_PLATFORM_SERVER = '/event-platform-server/get-event-platform-server',

  SURVEY_FORM = '/survey-form',
  SURVEY_RESPONSE = '/survey-response',
}

export enum NESTED_ROUTES {
  EVENTS = '/events',
  UPDATE_REGISTRANT = '/update',
  REGISTER_REGISTRANT = '/register',
  LOGIN_REGISTRANT = '/login',
  EMAIL_VERIFICATION = '/email-verification',
}

export const AccountRequiredRoutes = [
  'account',
  'registrant-form',
  'registrant',
  'event',
  'eventsite',
];
