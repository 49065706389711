import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/Buttons/Index';
import Input from 'components/Inputs';
import { LOCAL_CONSTANT } from 'constants/auth';
import { Regex } from 'constants/regex';
import { API_ROUTES } from 'constants/routes';
import { useRequest } from 'hooks/useRequest';
import { EmailPayload } from 'types/auth';

export default function ForgotPassword() {
  const { control, handleSubmit, setValue } = useForm<EmailPayload>();
  const { post, isLoading } = useRequest();
  const navigate = useNavigate();
  const { accountId, eventGroupId } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
  }, [email, setValue]);

  useEffect(() => {
    localStorage.removeItem(LOCAL_CONSTANT.USER_TOKEN);
  }, []);

  const onSubmit: SubmitHandler<EmailPayload> = async ({ email }) => {
    const res = await post<boolean, EmailPayload>(
      `${API_ROUTES.REGISTRANT}/forgot-password`,
      { email },
    );
    const encodedEmail = encodeURIComponent(email);
    if (!res.error) {
      navigate(
        `/verify-otp/${eventGroupId}/${accountId}?email=${encodedEmail}`,
      );
    }
  };

  return (
    <div className='md:w-[400px] mx-auto mt-40'>
      <div className='flex flex-col items-start'>
        <div className='sm:flex sm:items-center mb-6'>
          <div className='sm:flex-auto'>
            <h1 className='text-xl font-semibold leading-6 text-gray-900'>
              Forgot Password
            </h1>
            <p className='mt-2 text-sm text-gray-800'>
              Please enter your email address to continue.
            </p>
          </div>
        </div>
      </div>
      <div className='mx-auto'>
        <Controller
          control={control}
          name='email'
          defaultValue=''
          rules={{
            required: {
              message: 'Email is required',
              value: true,
            },
            pattern: {
              value: Regex.EMAIL,
              message: 'Invalid Email Address',
            },
          }}
          render={({ fieldState: { error }, field }) => (
            <Input
              onChange={field.onChange}
              error={error}
              label='Email Address'
              disabled={!!email}
              value={field.value}
              name={field.name}
              placeholder='Email Address'
              type='text'
              autoComplete='off'
            />
          )}
        />
        <div className='flex justify-end mt-3'>
          <Button
            label='Continue'
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
            variant='contained'
          />
        </div>
      </div>
    </div>
  );
}
